.container {
  max-width: 1380px !important;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 1380px) {
  .container {
      padding: 0 1.5rem;
  }
}