.contact-form-content {
    width: auto;
}

form  {
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
}

.name-container {
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
}

form input, 
form textarea {
    flex: 1;
    width: 100%;
    font-size: 0.9rem;
    color: #000;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom: 1.5 solid #000;
    padding: 1rem;
}

form button {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border: 1.5px solid #437fc7;
    border-radius: 0.6rem;
    background: #437fc7;
    padding: 0.7rem;
    transition: all 0.3s ease;
    cursor: pointer;
}

form button:hover {
    color: #000;
    background: #6daffe;
}

@media (max-width: 425px) {
    .contact-form-content {
        width: 22rem;
    }
}

@media (max-width: 375px) {
    .contact-form-content {
        width: 18.5rem;
    }
}