.contact-container {
    margin: 4rem;
    position: relative;
    width: 40rem;
}

.contact-container h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
}

.contact-content {
    display: flex;
    gap: 3rem;
}

@media (max-width: 768px) {
    .contact-container {
       margin: 2rem;
    }
}

@media (max-width: 425px) {
    .contact-container {
        overflow: hidden;
        width: 355px;
        margin-left: 1rem;
        
    }
}

@media (max-width: 375px) {
    .contact-container {
        overflow: hidden;
        width: 315px;
        margin-left: 1rem;
    }
}