.nav-wrapper {
    background-color: #437fc7;
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    z-index: 30;
    backdrop-filter: blur(50px);

}

.nav-content {
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 auto;
}

.nav-content h2 {
    color: #FFF;
}

.nav-content h2 a {
    text-decoration: none;
    color: #FFF;
}

.logo {
    margin-left: 1rem;
    width: 5rem;
    height: 4rem;
}

.nav-content ul {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
}

.nav-content li {
    margin: 0 1.5rem;
}

.menu-item {
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
    color: #FFF;
    position: relative;
    cursor: pointer;
}

.menu-item::before { 
    content: " ";
    width: 2rem;
    height: 0.2rem;
    background: linear-gradient(90deg, #edf6ff, #fff 100%);
    border-radius: 0.5rem;
    position: absolute;
    bottom: -0.6rem;
    opacity: 0;
    transform: translateX(-1.5rem);
    transition: all 0.3s ease;
}

.menu-item:hover::before {
    width: 100%;
    transform: translateX(0);
    opacity: 1;
}

.menu-btn {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    color: #FFF;
    background: linear-gradient(90deg, #437fc7, #6daffe 99.87%);
    line-height: 0;
    cursor: pointer;
    transition: all 0.4s ease;
    display: none;
}

.menu-btn:hover {
    color: #FFF;
    background: #437fc7;
    border: 1px solid #1c222a;
}

@media (max-width: 425px) {
    .logo {
        width: 4rem;
        height: auto;
    }
}

@media (max-width: 769px) {
    .menu-btn {
        display: block;
    }
    
    .nav-content ul {
        display: none;
    }
}

@media (max-width: 1323px) {
    .nav-wrapper {
        padding: 0 2rem;
    }

}